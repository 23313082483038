import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrEventGroupsView',
  computed: {
    ...mapState('registration', ['groups'])
  }
})

export default class GtrEventGroupsView extends GtrSuper {
  data () {
    return {
      loading: false,
      groupsData: [],
      groupsTable: {
        loading: false,
        items: [],
        headers: [
          {
            text: 'Group Name',
            align: 'start',
            sortable: true,
            value: 'name'
          },
          {
            text: 'Leader',
            align: 'start',
            sortable: true,
            value: 'leader'
          },
          {
            text: 'Amount Owed',
            align: 'start',
            sortable: true,
            value: 'amount'
          },
          {
            text: 'Registration Status',
            align: 'start',
            sortable: true,
            value: 'group_reg_status'
          }
        ]
      }
    }
  }

  async mounted () {
    await this.fetchGroups()
  }

  async fetchGroups () {
    try {
      this.$store.dispatch('common/showLoader', { value: true })
      const payload = {
        event_uuid: this.$route.params.event_uuid
      }
      const response = await this.$store.dispatch('registration/getGroups', payload)
      this.$data.groupsData = response.data
      this.$store.dispatch('common/hideLoader')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = true
      await this.buildGroupsTable()
      this.$data.loading = false
    }
  }

  async buildGroupsTable () {
    if (this.$data.groupsData) {
      let notification = false // used to only throw 1 notification
      // Loop through groups
      for (let i = 0; i < this.$data.groupsData.length; i++) {
        const group = this.$data.groupsData[i]
        const groupUuid = group.uuid ? group.uuid : ''
        let leader: Record<string, any> = {}
        let isGroupComplete = true
        // Loop through group members
        for (let j = 0; j < group.participant_group_participants.length; j++) {
          const member = group.participant_group_participants[j]
          if (member.parent) {
            leader = member
          }
          // Whole group is incomplete if one member is incomplete
          if (member.participant?.participant_data?.status === 'Incomplete') {
            isGroupComplete = false
          }
        }
        if (leader && !leader.participant && !notification) {
          // hide notification for now
          // Container.get(Notification).error('Certain group(s) have been omitted because the group leader record was deleted.')
          notification = true
          continue
        }
        // Build group objects for data table
        if (leader) {
          const groupObj = {
            name: group.group_name ? `${group.group_name} Group` : `${leader.participant?.participant_data?.full_name}'s Group`,
            leader: leader.participant?.participant_data?.full_name,
            amount: this.formatToCurrency(leader.participant?.participant_data?.cur_reg_fee_group),
            group_reg_status: isGroupComplete ? 'Complete' : 'Incomplete',
            group_uuid: groupUuid
          }
          this.$data.groupsTable.items.push(groupObj)
        }
      }
    }
  }

  get totalGroups () {
    return this.$data.groupsData.length
  }

  formatToCurrency (num: number) {
    return this.$options?.filters?.toCurrency(num)
  }

  handleGoBackToParticipants () {
    this.$router.push({
      name: 'level-two.event.attendees',
      params: {
        event_uuid: this.$route.params.event_uuid
      }
    })
  }

  handleGoToGroup (item: Record<string, any>) {
    this.$router.push({
      name: 'level-two.event.attendees.group',
      params: {
        event_uuid: this.$route.params.event_uuid,
        group_uuid: item.group_uuid
      }
    })
  }
}
